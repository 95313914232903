<template>
  <div :class="{ modal: true, 'is-active': modal?.visible }">
    <div class="modal-background" @click="$emit('closeModal')"></div>
    <div class="modal-content">
      <article
        :class="
          cssClasses || {
            message: true,
            'is-info': true,
          }
        "
      >
        <div class="message-header">
          <p>
            <slot name="header"></slot>
          </p>
          <Icon
            :icon="['far', 'times-circle']"
            :tooltip="'Close'"
            @click="$emit('closeModal')"
            class="has-text-light is-size-4"
          />
        </div>
        <div class="message-body  has-background-dark">
          <slot></slot>
        </div>
        <footer class="modal-card-foot">
          <slot name="footer"> </slot>
        </footer>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: { modal: Object, cssClasses: Object },
  emits: ["closeModal"],
  setup() {
    return {};
  },
};
</script>
