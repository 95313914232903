<template>
<div class="is-flex is-flex-wrap-wrap is-full-width">
    <div class="m-1 tag-owned is-float-left" v-if="item.owned && item.owned.length > 0">
    <Pill :tag="`owned:${item.owned}`" hideDeleteButton="true" @click="$emit('ownedClicked')"/>
    </div>
    <div class="m-1 tag-lent is-float-left" v-if="item.possessedby && item.possessedby.length > 0">
    <Pill :tag="`lent:${item.possessedby}`" hideDeleteButton="true" @click="$emit('possessedClicked')"/>
    </div>
    <div class="m-1 tag-visible is-float-left" v-if="item.visible2">
    <Pill :tag="`visible:${item.visible2.length > 0 ? (item.visible2.includes('*') ? 'public' : '(some) others') : 'private'}`" hideDeleteButton="true"  @click="$emit('visibleClicked')"/>
    </div>
</div>
</template>

<script>

import Pill from '@/components/Template/Pill';

export default {
    name:'PossessionPills',
    components:{Pill},
    props:["item","editable"],
    setup(props){

        const handleOwnedClicked = ()=>{
            console.log("owned clicked");
            if (props.editable && props.editable != false){
                // TODO: switch to editMode of owned
            }
        }

        return{
            handleOwnedClicked
        }
    }
}
</script>

<style>

</style>