<template>
  <div class="is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-center ml-3">
      <label class="mr-1">{{label}}</label>
      <input :class="{'slider is-70p-width':true,'is-info' :modelValue!==null}" 
        :step="step" :min="min" :max="max" 
        :value="modelValue" @input="onChanged"
        type="range"/>
      <input type="number" @input="onChanged" class="ml-1 slider-output" :value="modelValue"/>{{unit}}
  </div>  
</template>

<script>


export default {
  name: "Slider",
  props:['label','step','min','max','unit','modelValue'],
  components: { },
  setup(props,{emit}) {
    const onChanged = (e)=>{
        emit('update:modelValue', e.currentTarget.value);
    }
    return {onChanged}
  },
};
</script>
