/* eslint-disable no-unused-vars */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Icon from "@/components/Template/Icon";
import TabBar from '@/components/Page/TabBar.vue';
import Login from '@/components/ItemForm/Login.vue';
import BackButton from '@/components/Template/BackButton.vue'
import "./styles/main.scss";
import "./styles/print.scss";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue3TouchEvents from "vue3-touch-events";
import SearchItems from '@/views/SearchItems.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

createApp(App)
.component("BackButton",BackButton)
.component("TabBar",TabBar)
.component("SearchItems",SearchItems)
.component("Login",Login)
.component("Icon", Icon)
.component("Datepicker", Datepicker)
.use(store)
.use(router)
.use(Toast, 
    { position: POSITION.BOTTOM_RIGHT,toastClassName:'above-menu-bar toast-background-pink-1' }
    )
.use(Vue3TouchEvents,{swipeTolerance:50})
.mount('#app');