<template>
  <div class="mb-menu-bar">
    <Config />
    <button class="button mt-4" @click="$store.dispatch('logout')">LOGOUT</button>
    <div class="is-size-7">
      <hr/>
      <button class="button is-small is-rounded" @click="$router.push('/importCSV')">import items from CSV</button>
      <hr/>
      dingsda2mex frontend v{{version}}
      <br/>
      backend v{{backendversion}}
    </div>
    <hr>
  </div>
  <TabBar />
</template>

<script>
import TabBar from '@/components/Page/TabBar.vue'
import Config from '@/components/ItemForm/Config.vue'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'


export default {
  name: 'Landing',
  components:{
    TabBar,
    Config,
  },
  props: {
    msg: String
  },
  setup(){
    const store = useStore();

    const backendversion = computed(()=>store.state.data.backendVersion)

    const version = process.env.VUE_APP_VERSION;

    console.log(store.state.data);

    return{version,backendversion}
  }
}
</script>
