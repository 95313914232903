<template>
  <div class="home is-overflow-y-scroll is-full-height">
    <img alt="dingsda2mex logo" class="mt-5" style="height:50vh" src="../assets/logo.svg">
    <Landing/>
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '@/components/Page/Landing.vue'

export default {
  name: 'Home',
  components: {
    Landing
  }
}
</script>

<style lang="scss" scoped>


</style>
