<template>

    <div
      v-for="(storage,key) in containerArray"
      :key="key"
      class="mr-1"
    >
      <Pill v-if="!storage.name && key == 0" :tag="storage.id || '?'" @onTagClick="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true" inputclass="rootStorageTag"/>
      <Pill v-if="!storage.name && key != 0" :tag="storage.id || '?'" @onTagClick="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true" inputclass="basicStorageTag"/>

      <Pill v-if="storage.name && key == 0" :tag="storage.name || '?'" @onTagClick="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true" inputclass="rootStorageTag"/>
      <Pill v-if="storage.name && key != 0" :tag="storage.name || '?'" @onTagClick="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true" inputclass="basicStorageTag"/>

    </div>

</template>

<script>

import Pill from '@/components/Template/Pill.vue'


export default {
  name: "ContainerPath",
  props: ["containerArray"],
  emits: [],
  components: { Pill },
  setup() {
    return {};
  },
};
</script>
