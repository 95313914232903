
const shouldBeShownByCategory = (fieldname,category,store,defaultToHide=false)=>{
  let fieldDefinition = store.state.ui.field2categoryMap[fieldname];
  if (category.toLowerCase() == "all"){
    return true
  }
  if (fieldDefinition){
    if (fieldDefinition.showIn && (fieldDefinition.showIn.includes("*") || fieldDefinition.showIn.includes(category))){
      return true
    }
    if (fieldDefinition.hideIn && (fieldDefinition.hideIn.includes("*") || fieldDefinition.hideIn.includes(category))){
      return false
    }
  }
  if(defaultToHide){
    return false
  }
  return true
}

const itemLendingDirection = (item,store)=> {
  if (item.owned.includes(store.state.data.username) || item.owned.some((group)=>store.state.data.usergroups.includes(group))){
    // item is owned by user or a group user belongs to
    if (item.possessedby.length > 0){
      // item owned by user/group is with another user
      return "LENT"
    }
  }
  else{
    //item is NOT owned by user or group user belongs to
    if (item.possessedby.includes(store.state.data.username) ){
      //item is in possession of user themselves
      return "BORROWED"
    }
    else if ( item.possessedby.some((group)=>store.state.data.usergroups.includes(group)) ){
      // item in possession of usergroup that user is part of
      return "BORROWED"
    }
  return undefined // all other cases: neither LENT NOR BORROWED
  }


  // if (item.owned.includes(store.state.data.username) || store.state.data.usergroups.includes(item.possessedby)){
  //   return "LENT"
  // }
  // return "BORROWED"
}

const isUserOwnedItem = (item,store)=>{
  if (item.owned.includes(store.state.data.username) || store.state.data.usergroups?.some((group)=>item.owned.includes(group))){
    return true
  }
  return false
}

const isUserOwnedItemOrBorrowed = (item,store) =>{
  if (isUserOwnedItem(item,store)) return true
  if (item.possessedby && item.possessedby.length > 0 && itemLendingDirection(item,store) == "BORROWED") return true
  return false
}

const isMobile = ()=>{
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

const dataURItoBlob = (dataURI)=>{
  let byteString = atob(dataURI.split(',')[1]);
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  let arrb = new ArrayBuffer(byteString.length);
  let inta = new Uint8Array(arrb);
  for (let i = 0; i < byteString.length; i++) {
      inta[i] = byteString.charCodeAt(i);
  }
  let blob = new Blob([arrb], {type: mimeString});
  return blob;
}

const getDataUriFromImageUrl = async (imageUrl)=>{
  let blob = await fetch(imageUrl).then(f =>f.blob());
  let dataUri = await new Promise(resolve => {
    let filereader = new FileReader();
    filereader.onload = () => resolve(filereader.result);
    filereader.readAsDataURL(blob);
  });
  return dataUri
}

// CONTINUE HERE:

/*
TODO: 
- use sth like the following to make image to datauri before all processing
- check how to get image size for canvas before rotation

getDataUriFromImageUrl(image.src).then((s)=>{
  image.src=s  
})
*/

const rotateImage90DegToDataUrl = (img,rightTurn=true)=>{
    console.log("canvas should be of size:",img.naturalWidth,img.naturalHeight);
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    var cw = img.naturalHeight, ch = img.naturalWidth, cx = 0, cy = 0;

    canvas.setAttribute('width', cw);
    canvas.setAttribute('height', ch);
    if (rightTurn){
        ctx.rotate(90 * Math.PI / 180);
        ctx.translate(0,-cw)
    }
    else{
        ctx.rotate(270 * Math.PI / 180);
        ctx.translate(-ch,0)
    }
    
    ctx.drawImage(img, cx, cy);
    
    //console.log(canvas.toDataURL());
    
    return canvas.toDataURL();

}

function removeParameterFromURL(key, originalURL) {
  let param;
  let paramsArray = [];
  let output = originalURL.split("?")[0];
  let query = originalURL.includes("?") ? originalURL.split("?")[1] : "";
  
  if (query && query.length > 0) {
      paramsArray = query.split("&");
      for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
          param = paramsArray[i].split("=")[0];

          if (param === key) {
              paramsArray.splice(i, 1);
          }
      }
      if (paramsArray.length) output = output + "?" + paramsArray.join("&");
  }
  return output;
}

function mapFormFieldsToDBFields(item){
  return {
    name:item.name,
    owners:item.owned,
    inPossessionOf:item.possessedby,
    visible2: item.visible2,
    tags:item.tags,
    weight:parseFloat(item.weight),
    length:parseInt(item.length),
    width:parseInt(item.width),
    depth:parseInt(item.depth),
    pictures:item.pictures,
    price:parseFloat(item.price),
    value:parseFloat(item.value),
    count:parseInt(item.count),
    description:item.description,
    countryoforigin: item.countryoforigin,
    billingreference: item.billingreference,
    invoicedate: item.invoicedate,
  }
}


export { 
  shouldBeShownByCategory,
  itemLendingDirection,
  isUserOwnedItem,
  isUserOwnedItemOrBorrowed,
  isMobile,
  dataURItoBlob,
  getDataUriFromImageUrl,
  rotateImage90DegToDataUrl,
  removeParameterFromURL,
  mapFormFieldsToDBFields,
}