import { isEqual,uniqWith } from "lodash";

export const mutations = {
  getData(state,{key}){
    return state["data"][key]
  },
  setData(state, { key, value }) {
    //console.log("setting data",key, value);
    state["data"][key] = value;
  },
  pushData(state,{key,value}){
    if (Array.isArray(value)){
      state["data"][key].push(...value);
    }
    else{
      state["data"][key].push(value);
    }
  },
  popData(state,{key}){
      state["data"][key].pop();
  },
  removeArrayDataByIndex(state,{key,index}){
    if(!key && !index){throw 'removeArrayDataByIndex needs key and index'}
    console.log("deleting",key,index,state["data"]);
    state["data"][key].slice(index,1);
  },
  removePhotosFromUploadList(state){
    state.data.photosToUpload = null;
    console.log("state.data.photosToUpload emptied",state.data.photosToUpload);
  },
  unshiftData(state, { key, value, maximum = null}) {
    if (typeof value === Array){
      state["data"][key].unshift(...value);
    }
    else{
      state["data"][key].unshift(value);
    }
    if (maximum && state["data"][key].length > maximum) {
      state["data"][key].length = maximum;
    }
  },
  setSelected(state, { key, value }) {
    state["selected"][key] = value;
  },
  addBasketItems (state, {items}) {
    state["data"]["basket"].push(...items);
    let unique = uniqWith(state["data"]["basket"],isEqual);
    state["data"]["basket"] = unique;
    return state["data"]["basket"]
  },
  emptyCart (state){
    state.data.basket = [];
    return state.data.basket
  },
  removeItemsFromListById(state, {key,items}){
    let list = state['data'][key];
    if (!Array.isArray(list)){
      console.error(list)
      throw 'key does not specify an array'
    }
    for (let item of items){
      list = list.filter((elem) => elem.id !== item.id);
    }
    state['data'][key] = list;
    return list
  },
  setPrompt(state, { message, webhook, schema, visible }) {
    state.prompt = { message, webhook, schema, visible };
  },
  cleanSearchFilter(state){
    // remove all searchFilter that are not also present in SearchResultTags (the set of possible filters)
    let allPossibleTags = state.data.searchResultTags.map((t)=>t.tag);
    state.data.searchResultFilter = state.data.searchResultFilter.filter((f)=>{
      return allPossibleTags.includes(f.tag)
    },[])
    console.log("cleaned SearchResultFilters in store to",state.data.searchResultFilter);
  }
  ,
  addSearchFilter(state,{filter}){
    if (!state.data.searchResultFilter.includes(filter)){
      state.data.searchResultFilter.push(filter);
    }
  },
  removeSearchFilter(state,{filter}){
    //if (!state.data.searchResultFilter.includes(filter)){
      console.log(state.data.searchResultFilter, "looking for",filter);
      let index = state.data.searchResultFilter.findIndex( element => isEqual(element, filter));
      console.log("REMOVESEARCHFILTER",index);
      if (index !== -1){
        state.data.searchResultFilter.splice(index,1); 
      }
      
    //}
  },
  addSearchFilterStorage(state,{filter}){
    if (!state.data.searchResultFilterStorage.some((s)=> s.id == filter.id)){
      state.data.searchResultFilterStorage.push(filter);
    }
  },
  removeSearchFilterStorage(state,{filter}){
      let index = state.data.searchResultFilterStorage.findIndex( element => {
        if (isEqual(element, filter)) {
          return true;
        }
    });
    state.data.searchResultFilterStorage.splice(index,1);
  },
  addSearchFilterGeolocation(state,{area,center,radius}){
    area.center = center;
    area.radius = radius;
    state.data.searchResultFilterGeolocation = area;
  },
  removeSearchFilterGeolocation(state){
    state.data.searchResultFilterGeolocation = null;
  },
  setConfigValue(state,{key,value}){
    state.ui.config[key] = value;
  },
  setUI(state, { key, value }) {
    console.log("setting ui",key, value);
    state["ui"][key] = value;
  },
};
