<template>
  <Modal 
      v-if="addMembersModal.visible"
      :modal="addMembersModal"
      @closeModal="addMembersModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          ADD MEMBER TO GROUP
      </template>
      <template v-slot class="is-full-width">
          <div class="has-background-dark has-text-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2">Attention: Members have FULL CONTROL over ALL YOUR ITEMS!</h1>

              <input class="input" type="text" placeholder="username" v-model="addMembersModal.newMember">
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" 
            :disabled="!(addMembersModal.newMember?.length > 0)" @click="handleAddMember">
            ADD MEMBER
          </button>
          <button class="button has-background-pink-1 has-text-light" 
            @click="addMembersModal.visible = false">
            CANCEL
          </button>
      </template>
  </Modal>

  <div class="config is-flex is-flex-direction-column is-justify-content-center">

    <h1 class="m-3"></h1>

      <p>Hallo, {{$store.state.data.username}}!</p>

      <h3>You are member of these groups:</h3>
      <div class="is-flex is-flex-direction-row is-justify-content-center 
        is-overflow-x-scroll is-overflow-y-hidden mb-2 invisible-scrollbar">
        <div class="m-1" v-for="group in $store.state.data.usergroups" :key="group">
          <Pill class="userpill" :tag="group" hideDeleteButton="true"/>
        </div>
      </div>

      
      <h3>Your members:</h3>
      <div class="is-flex is-flex-direction-row is-justify-content-center is-overflow-x-scroll mb-2 invisible-scrollbar">
        <div class="m-1" v-for="member in $store.state.data.groupmembers" :key="member">
          <Pill class="userpill" :tag="member" @onDeleteClick="handleRemoveMember(member)"/>
        </div>
      </div>
      <div>
        <button class="button is-third-width m-1" @click="addMembersModal.visible = true">ADD MEMBERS</button>
      </div>

      
      <h3 class="mt-2">Your Storage Facilities: </h3>
    
      <div class="is-full-width is-flex is-flex-direction-row is-justify-content-center ">
        <div class="is-flex is-flex-direction-row is-justify-items-center m-2 is-overflow-x-scroll invisible-scrollbar" :class="isMobile() ?'':'is-flex-wrap-wrap is-justify-content-center'">
          <div v-for="storage in rootStorages.sort((a, b) =>a.name > b.name ? 1 : -1)" :key="storage.id">
            <Pill class="m-1" :tag="storage.name" @click="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true"/> 
          </div>
        </div>
      
      </div>
      
      <div>
        <button class="button is-third-width m-1" @click="$router.push('/AddDing?showGeolocation=true')">ADD STORAGE</button>
      </div>
        
<hr>

<div class="is-flex is-flex-direction-column is-align-items-center">

  <div class="checkbox is-80-pc-width">
      <label>
      <input @click="handleRememberStorageChange" type="checkbox" :checked="rememberLastStorageInAdd">
        remember storage location of item
      </label>
    </div>

    <div class="checkbox is-80-pc-width">
      <label>
      <input @click="handleItems2Basket" type="checkbox" :checked="items2basket">
        add items to basket after creation
      </label>
    </div>

</div>

    <hr>

    <div class="is-flex is-flex-direction-column is-align-items-center m-2 is-overflow-x-scroll invisible-scrollbar">
        <label for="reservationnotesinput" >add template text for reservation notes:</label>
        <div class="is-half-width">
          <textarea id="reservationnotesinput" class="textarea" @change="handleReservationNotesTemplateUpdate" ref="reservationnotestemplate"/>
        </div>
    </div>

    <div class="is-flex is-flex-direction-column is-align-items-center m-2 is-overflow-x-scroll invisible-scrollbar">
      <div>Your dingsda2mex cloud printer</div>
      <input class="input is-half-width" ref="cloudprinter" @change="handleCloudPrinterUpdate" type="text" placeholder="printer id">
    </div>

    <hr>

    <div>
      <form class="is-flex is-flex-direction-column is-align-items-center">
        <h1>change password</h1>
        <input class="input is-half-width m-1" type="password" ref="newpassword1" placeholder="newpassword" autocomplete="off">
        <input class="input is-half-width m-1" type="password" ref="newpassword2" placeholder="repeat newpassword" autocomplete="off">
        <button type='button' class="button m-1" @click="handleChangePasswordClicked">change password</button>
      </form>
    </div>

    <hr>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from "vuex";
import { nextTick, onMounted } from '@vue/runtime-core';
import {isMobile} from '@/helpers.js'

import Pill from '@/components/Template/Pill.vue';
import Modal from '@/components/Template/Modal.vue';

export default {
  name: 'Config',
  components:{Pill,Modal},
  setup(){

      const store = useStore();

      const addMembersModal = ref({visible:false,newMember:null})

      const rootStorages = ref([]);
      const rememberLastStorageInAdd = ref(store.state.ui.config.rememberLastStorageInAdd);
      const items2basket = ref(store.state.ui.config.items2basket);
      
      const cloudprinter = ref(null);
      const reservationnotestemplate = ref(null);

      const handleItems2Basket = (e)=>{
        nextTick(()=>{
          store.dispatch('setConfigValue',{key:'items2basket',value:e.target.checked})
        })
      }

      const handleRememberStorageChange = (e)=>{
        nextTick(()=>{
          store.dispatch('setConfigValue',{key:'rememberLastStorageInAdd',value:e.target.checked})
        }) 
      }

      const handleRemoveMember = async (e)=>{
        let done = await store.dispatch('deleteMember',{id:e});
        if (done){store.dispatch("testconnection")} 
      }

      const handleAddMember = async ()=>{
        let done = await store.dispatch('addMember',{id:addMembersModal.value.newMember});
        if (done){addMembersModal.value.newMember = null;store.dispatch("testconnection")}
      }

      const handleCloudPrinterUpdate = () =>{
        console.log("cloudprinter update",cloudprinter?.value?.value);
        store.dispatch('setConfigValue',{key:"cloudprinter",value:cloudprinter?.value?.value})
      }

      const handleReservationNotesTemplateUpdate = () =>{
        console.log("reservationnotestemplate update",reservationnotestemplate?.value?.value);
        store.dispatch('setConfigValue',{key:"reservationnotestemplate",value:reservationnotestemplate?.value?.value})
      }

      const newpassword1 = ref(null);
      const newpassword2 = ref(null);
      const handleChangePasswordClicked = async () =>{
        const newpw1 = newpassword1.value.value;
        const newpw2 = newpassword2.value.value;
        if (newpw1 !== newpw2) return store.dispatch('triggerToast',{message:'passwords are not identical!'})
        let success = await store.dispatch("changePassword",newpw1);
        if (success) {
          newpassword1.value.value = '';
          newpassword2.value.value = '';
          store.dispatch('triggerToast',{message:'password changed',type:'success'})
        }
      }

      onMounted(async()=>{
        rootStorages.value = await store.dispatch('getAllRootContainers');
        cloudprinter.value.value = store.state.ui.config.cloudprinter; 
        reservationnotestemplate.value.value = store.state.ui.config.reservationnotestemplate;
      })
      
      return {
        handleRememberStorageChange,
        handleItems2Basket,
        handleRemoveMember,
        rememberLastStorageInAdd,
        items2basket,
        rootStorages,
        addMembersModal, handleAddMember,
        cloudprinter,handleCloudPrinterUpdate,
        reservationnotestemplate,handleReservationNotesTemplateUpdate,
        handleChangePasswordClicked,newpassword1,newpassword2,
        isMobile,
      }
  }
}
</script>
