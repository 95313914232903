<template>
  <dialog ref="loadingAllModal">
  <p>Hang on! Loading all search Results.</p>
  <p>This can take a while, depending on how many there are.</p>
  <button type="button" autofocus @click="loadingAllModal.close()">STOP and CANCEL</button>
  </dialog>
  <div class="search-ding is-full-width is-full-height is-flex is-flex-direction-column">
    
    <div class="is-flex has-flex-direction-row is-full-width">
      <div class="spacer-empty" v-if="$route.query.rootContainer">
        <back-button v-if="$route.query.rootContainer"/>
      </div>
      <Autocomplete
                ref="searchTermInput"
                debounce=1000
                max=5
                use-html-for-results=true
                input-minlength="1"
                input-class="input left is-full-width"
                results-container-class="autocomplete-result-container"
                results-item-class="green-on-hover vue3-results-item"
                placeholder="search"
                :results="searchTermResults"
                @input="handleNewSearchInput"
                @onSelect="handleTagAdded($event,searchTermInput)"
                @keypress.enter="handleSearchButtonPressed"
                @blur="handleSearchInputBlur"
                clear-button="true"
        ></Autocomplete>
        <button 
          class="button"
          @click="handleSearchButtonPressed($event)"
        >SEARCH</button>
    </div>
    <div class="is-flex is-flex-direction-row is-justify-content-space-between">
      <div class="is-flex is-flex-direction-row">
         <!-- {{ chosenFilters }} -->
        <Pill 
          class="mt-2 ml-2 mb-0" 
          tag="Location" 
          @onTagClick="locationFilterVisible = !locationFilterVisible"
          hideDeleteButton="true"/>
        <div class=" mt-3 is-flex flex-direction-row is-flex-wrap-wrap">
          <div v-if="chosenArea">
            <Pill class="mt-0 ml-2 mb-0 geoLocationTag rootStorageTag taghead" 
                :tag="
                  `${chosenArea.center.geometry.coordinates[0].toString().substring(0, 7)}.. , 
                  ${chosenArea.center.geometry.coordinates[1].toString().substring(0, 7)}.. , 
                  ${chosenArea.radius}m rad.`"
                @onDeleteClick="$store.dispatch('removeSearchFilterGeolocation')"/>
          </div>
          <div v-for="chosen in chosenLocations" :key="chosen">
            <Pill class="mt-0 ml-2 mb-0 geoLocationTag rootStorageTag taghead" :tag="chosen.name"
                  @onDeleteClick="$store.dispatch('removeSearchFilterStorage',{filter:chosen})"/>
          </div>
        </div>
      </div>
      <div>
        <button class="button is-small m-1 is-rounded has-background-purple-5" @click="toggleSortItems">
          {{ sortingAlgorithmChosen == 0 ? ' ID  ⬇️' : sortingAlgorithmChosen == 1  ? 'A-Z ⬆️' : 'Z-A ⬇️' }}
        </button>
        <!-- <button class="button is-small m-1 is-rounded has-background-purple-5" @click="togglePagination">
          {{ paginationOff ? 'ALL AT ONCE' : 'PAGE BY PAGE' }}
        </button> -->
      </div>
    </div>
    <SearchFilterLocation 
      v-if="locationFilterVisible" class="is-fixed is-z-2 is-full-height is-full-width"
      :possibleRootContainers="possibleRootContainers"
      :chosenLocations="chosenLocations"
      @close="onSearchLocationFilterClose"
    />

    <div class="is-flex flex-direction-row is-full-width">
      <Pill v-if="/*searchResults.length > 0 ||*/ chosenFilters.length > 0 || searchResultTags.length > 0 || availableFrom || availableTill || atLeastOneNumericalFilterActive()" class="ml-2" tag="Filters" 
      @onTagClick="searchFilterVisible = !searchFilterVisible" hideDeleteButton="true"/>
       <div class="is-flex flex-direction-row is-flex-wrap-wrap">
        <div v-for="chosen in chosenFilters" :key="chosen">
          <Pill class="m-1 mt-3 tag-small" :tag="chosen.tag"
                @onDeleteClick="removeFilter(e,chosen)"/>
        </div>
        <div v-if="atLeastOneNumericalFilterActive()">
           <Pill class="m-1 mt-3 tag-small" tag="minMax filters"  @onDeleteClick="clearNumericalFilters"/>
        </div>
        <div v-if="availableFrom || availableTill">
           <Pill class="m-1 mt-3 tag-small solotag" :tag="availabilityAsString()"  @onDeleteClick="clearAvailabilityFilters"/>
        </div>
      </div>
    </div>

    <!-- TODO: TagFilter Component instead of Collapsible -->
    <div v-if="searchFilterVisible" class="is-fixed  is-z-2 is-full-height is-full-width 
    is-overflow-y-scroll searchfilter-page has-background-purple-5 is-flex is-flex-direction-column">
        
        <Icon class="is-fixed is-right-0 is-top-0 is-size-4 has-text-dark m-5" 
        :icon="['far', 'times-circle']" @click="onSearchFilterClose"/>
    
        <div class="is-full-width">
          <h1 class="m-2 ml-4 has-text-weight-bold is-size-5 is-float-left">Filters</h1>
        </div>

        <h5 class="is-size-7 ml-2 is-text-align-start"> Tags / Specifics </h5>
        <div class="is-flex is-flex-direction-row is-flex-wrap-wrap ml-2">
            <div v-for="tag in searchResultTags" :key="tag.tag">
              <Pill 
              :class="{'activeFilter':!tagNotInChosenFilters(tag),'searchFilterTag ml-1 mt-1':true}" 
              :tag="tag.tag" 
              @onTagClick="toogleChosenFilterClick($event,tag)" 
              hideDeleteButton="true"/>
            </div>
        </div>

        <hr>

        <h5 class="is-size-7 ml-2 is-text-align-start"> Available </h5>
        <div>
          <div>
              <input type="datetime-local" name="from" @change="handleFromTimeChanged" :value="availableFrom">
              <div>-</div>
              <input type="datetime-local" name="till" @change="handleTillTimeChanged" :value="availableTill">
          </div>
        </div>
        
        <hr>
        
        <h5 class="is-size-7 ml-2 is-text-align-start"> Weight </h5>
        <Slider label="min" step="0.5" min="0" max="200" v-model="minWeight" unit="kg"/>
        <Slider label="max" step="2" min="0" max="999" v-model="maxWeight" unit="kg"/>
       
        <h5 class="is-size-7 ml-2 is-text-align-start"> Length </h5>
        <Slider label="min" step="5" min="0" max="1000" v-model="minLength" unit="cm"/>
        <Slider label="max" step="5" min="0" max="10000" v-model="maxLength" unit="cm"/>

        <h5 class="is-size-7 ml-2 is-text-align-start"> Width </h5>
        <Slider label="min" step="1" min="0" max="1000" v-model="minWidth" unit="cm"/>
        <Slider label="max" step="5" min="0" max="5000" v-model="maxWidth" unit="cm"/>
       
        <h5 class="is-size-7 ml-2 is-text-align-start"> Height </h5>
        <Slider label="min" step="1" min="0" max="1000" v-model="minDepth" unit="cm"/>
        <Slider label="max" step="5" min="0" max="5000" v-model="maxDepth" unit="cm"/>
       
    </div>


    <div id="searchResults" class="search-results" ref="searchResultsBox" @scroll="trackscroll">
      <div class="list-item-container" v-for="item in searchResults" v-bind:key="item.id"> 
        <ListItem 
          v-if="item" 
          :item="item" 
          @click="handleItemClicked($event,item)" 
          :class="{
            'is-select-mode':selectModeOn, 
            'is-selected':selectedItems.find((i)=>i?.id == item?.id)
            }" 
            :removebutton="false" />
      </div>
      <div class="button" ref="loadMoreItemsButton" :class="{
        'has-background-pink-5':loadMoreItemsButtonText != '... more ...'}" 
        @click="searchNextPage"> {{loadMoreItemsButtonText}} </div>
      <div style="height:6rem"></div>
       
    </div>
  </div>

  
  <div :class="{'listButtonBar mb-1 is-flex is-flex-direction-column is-full-width':true,'is-select-mode':selectModeOn}">
    <div class="mb-1 is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
      <div>
        
      </div>
      <div>
        <button v-if="$store.state.data.searchResultItems.length > 0" :class="{'mr-2 button is-rounded has-background-purple-1 has-text-light':true,'has-background-purple-5 has-text-dark':selectModeOn}" @click="handleSelectClicked">{{selectModeOn ? "READY" : "SELECT"}}</button>        
      </div>
    </div>
    <div class="selectButtonContainer is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
      <div>
        <button class="ml-2 button is-rounded" v-if="selectModeOn" @click="handleSelectAllClicked">☑️ SELECT ALL</button> 
      </div>
      <div>
        <button class="mr-2 button is-rounded" v-if="selectModeOn && selectedItems.length > 0" @click="handleAdd2Basket">ADD TO BASKET</button>
      </div>
    </div>        
  </div>
  <div style="height: 11vh"></div>
  
</template>

<script>
import { computed, ref, onMounted, onActivated } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter} from "vue-router"

import Autocomplete from '@/components/Template/Autocomplete'
import Pill from '@/components/Template/Pill'
import ListItem from '@/components/Template/ListItem'
import SearchFilterLocation from '@/components/Page/SearchFilterLocation'
import Slider from '@/components/Template/Slider'
import BackButton from '@/components/Template/BackButton.vue';

//import {removeParameterFromURL} from '@/helpers'

import { isEqual } from "lodash";

export default {
  name: 'SearchItems',
  components: {
    Autocomplete,
    Pill,
    ListItem,
    SearchFilterLocation,
    Slider,
    BackButton
  },
  setup(){
    const store = useStore();

    const router = useRouter();

    const searchTermInput = ref(null);
    const searchTermResults = ref([]);
    const searchResultsCollapsed = false;
    const searchResults = ref([]);
    const searchResultTags = computed(()=> store.state.data.searchResultTags);
    const chosenFilters = computed(()=> store.state.data.searchResultFilter);
    const possibleRootContainers = ref(null);
    const chosenLocations = computed(()=> store.state.data.searchResultFilterStorage);
    let filterArchives = {availableFrom:null, availableTill: null, chosenLocations:[],chosenFilters:[],numericalFilters:[null, null, null, null, null, null, null, null],chosenArea:null};
    const chosenArea = computed(()=> store.state.data.searchResultFilterGeolocation);

    let sortingAlgorithms = [
      function (a,b){return a.id.localeCompare(b.id)},
      function (a,b){return a.name.localeCompare(b.name)},
      function (a,b){return b.name.localeCompare(a.name)}
    ]

    let sortingAlgorithmChosen = ref(0);

    function toggleSortItems(){
      //console.log("sort toggled");
      if (sortingAlgorithmChosen.value >= sortingAlgorithms.length - 1){
        sortingAlgorithmChosen.value = 0
      }
      else{
        sortingAlgorithmChosen.value = sortingAlgorithmChosen.value+1;
      }
      sortItems()
    }

    function sortItems(){
      if (sortingAlgorithmChosen.value){
          searchResults.value = [].concat(store.state.data.searchResultItems).sort(sortingAlgorithms[sortingAlgorithmChosen.value])
      }
      else{
        searchResults.value = [].concat(store.state.data.searchResultItems)
      }
    }

    store.subscribe((mutation,state) => {
      if(mutation.payload?.key == 'searchResultItems'){
        //console.log("searchResultItems changed",state.data.searchResultItems);
        if (sortingAlgorithms[sortingAlgorithmChosen.value]){
          searchResults.value = [].concat(state.data.searchResultItems).sort(sortingAlgorithms[sortingAlgorithmChosen.value])
        }
        else{
          searchResults.value = [].concat(state.data.searchResultItems)
        }
      }
    })
    // availability filters
    const availableFrom = ref(null);
    const availableTill = ref(null);


    // numeric filters min/max
    const minWeight = ref(null);
    const maxWeight = ref(null);

    const minLength = ref(null);
    const maxLength = ref(null);
    
    const minWidth = ref(null);
    const maxWidth = ref(null);
    
    const minDepth = ref(null);
    const maxDepth = ref(null);

    const numericalFilters = computed(()=>[
        minWeight,maxWeight,minLength,maxLength,
        minWidth,maxWidth,minDepth,maxDepth
      ])
    
    const selectModeOn = ref(false);
    const locationFilterVisible = ref(false);
    const searchFilterVisible = ref(false);
    const selectedItems = ref([]);

    const loadMoreItemsButton = ref(null);
    const loadMoreItemsButtonDefaultValue = "... more ..."
    const loadMoreItemsButtonText = ref(loadMoreItemsButtonDefaultValue)

    const loadingAllModal = ref(null);
    const paginationOff = ref(true);

    const togglePagination = ()=>{
      paginationOff.value = !paginationOff.value;
    }

    const handleSearchInputBlur = ()=>{
      //console.log("searchInput blurred");
      searchTermResults.value = [];
    }

    // TODO: move to helpers.js
    function htmlString2DomElement(html) {
      html = html.trim();
      let elementTemplate = document.createElement('template');
      elementTemplate.innerHTML = html;
      return elementTemplate.content.firstChild;
    }

    const handleTagAdded = (event,element) => {
      console.log("handleTagAdded",event,element);
      
      let tag = htmlString2DomElement(event).getAttribute("data-content");
      //console.log("adding tag ",tag);
      if (!tag) return
      addFilter(event,{tag})
      searchTermResults.value = [];
      //remove last word from input for we chose it as tag
      let searchText = searchTermInput.value.searchText;
      let lastSpaceIndex = searchText.lastIndexOf(" ");
      let searchTextWithoutLastWord = searchText.substring(0, lastSpaceIndex);
      if (lastSpaceIndex === -1) searchTextWithoutLastWord = '';
      searchTermInput.value.setText(searchTextWithoutLastWord);
      // TODO: make keyboard able to choose tags from list
    }

    const handleNewSearchInput = (event) =>{
      //console.log("handleNewSearchInput",event, searchTermResults.value);
      searchTermResults.value = [];
      if (event.trim() == "") return;
      console.log("tags available",store.state.data.searchResultTags);
      let searchTermArray = event.split(" ");
      let searchterm = searchTermArray[searchTermArray.length-1].toLowerCase();
      let matches = store.state.data.searchResultTags?.filter((tag)=>{
        return tag.tag.toLowerCase().includes(searchterm)
      })
      //console.log("matches",matches);
      for (let tag of matches){
        if (tag.tag.includes(":")){
          searchTermResults.value.push( // TODO: use this use of data-content and htmlString2DomElement as standard for other uses of this tag!!!
        `<div class="tags display-unset has-addons" data-content='${tag.tag}'><span class="tag taghead is-darkgrey">${tag.tag.split(":")[0]}</span><span class="tag tagtail is-dark has-text-white has-text-weight-bold">${tag.tag.split(':')[0]}</span></div> (add as tag filter)`
          
        )
        }
        else
        {
          searchTermResults.value.push( // TODO: use this use of data-content and htmlString2DomElement as standard for other uses of this tag!!!
          `<dingsda-tag class='tag is-darkgrey' data-content='${tag.tag}'>${tag.tag}</dingsda-tag> (add as tag filter)`
        )
        }
        
      }
    }

    const onSearchLocationFilterClose = () => {
      locationFilterVisible.value = false;
      let changed = false;
      changed = changeInFilterArraysDetected(chosenLocations.value,"chosenLocations");
      changed = changeInAreaFilterDetected() || changed;
      if (changed) handleSearchButtonPressed()
    }

    const onSearchFilterClose = () => {
      searchFilterVisible.value = false; 
      let changed = false;
      changed = changeInFilterArraysDetected(chosenFilters.value,"chosenFilters");
      changed = changeInNumericalFiltersDetected() || changed;
      changed = changeInAvailabilityFilterDetected() || changed;
      console.log("filter changes detected?:",changed);
      if (changed) handleSearchButtonPressed()
    }

    const changeInAreaFilterDetected = () => {
      if (!isEqual(filterArchives['chosenArea'], chosenArea.value)) {
        //console.log("area filter changed",filterArchives['chosenArea'],chosenArea.value);
        filterArchives['chosenArea'] = Object.assign({},chosenArea.value);
        return true
      }
      return false
    }

    const changeInAvailabilityFilterDetected = () =>{
      if(filterArchives['availableFrom'] !== availableFrom.value || filterArchives['availableTill'] !== availableTill.value){
        //console.log("availabilityFilter changed");
        filterArchives['availableFrom'] = availableFrom.value;
        filterArchives['availableTill'] = availableTill.value;
        return true
      }
      return false
    }

    const changeInNumericalFiltersDetected = () => {
      let newValues = 
      [
        minWeight.value,maxWeight.value,minLength.value,maxLength.value,
        minWidth.value,maxWidth.value,minDepth.value,maxDepth.value
      ]
      return changeInFilterArraysDetected(newValues, "numericalFilters")
    }

    const atLeastOneNumericalFilterActive = ()=>{
      const result = 
      [
        minWeight,maxWeight,minLength,maxLength,
        minWidth,maxWidth,minDepth,maxDepth
      ].some(v => v.value !== null)
      //console.log("atLeastOneNumericalFilterActive",result);
      return result
    }

    const clearNumericalFilters = ()=>{
      [
        minWeight,maxWeight,minLength,maxLength,
        minWidth,maxWidth,minDepth,maxDepth
      ].forEach(v => v.value = null)
      //console.log("cleared numerical Filters");
    }

    const clearAvailabilityFilters = ()=>{
      availableFrom.value = null;
      availableTill.value = null;
    }

    /**
     * checks if changed. if so: trigger search. then: do for other filters
    */
    const changeInFilterArraysDetected = (newArray,oldArrayName) =>{
      let oldArray = filterArchives[oldArrayName];
      if ( 
        newArray.length !== oldArray.length || 
        newArray.some((value, index) => value !== oldArray[index])){
          console.log("filter changed",newArray,oldArray);
          filterArchives[oldArrayName] = Array.from(newArray);
          return true
        }
      return false
    }

    const handleFromTimeChanged = (e)=>{
      //console.log("from",e.target.value);
      // TODO: add to requests. then add to backend.
      availableFrom.value = e.target.value;
    }

    const handleTillTimeChanged = (e)=>{
      //console.log("till",e.target.value);
      availableTill.value = e.target.value;
    }

    const handleSelectClicked = () =>{
     //console.log("select clicked");
      // activate SELECTION MODE
      selectModeOn.value = !selectModeOn.value;
      // empty selection
      selectedItems.value = [];
    } 

    const handleItemClicked = (event,item)=>{
      if (selectModeOn.value){
        return toggleItemSelect(item)
      }
      router.push(`/Detail?id=${item.id}`);
    }

    /**
     * will activate autopagination, trigger searchNextPage() and then wait for the 'last page fetched' event to selectAllItems()
     */
    const handleSelectAllClicked = async()=>{
      // TODO: CONTINUE HERE: yield for lastPage fetched event (dispatch it first) and then continue
      // TODO: show modal: "hang on... loading all searchResults. That can take a while..." with cancel (that resets paginationOff.value)
      paginationOff.value = false;
      loadingAllModal.value.showModal();
      searchNextPage()
      document.addEventListener('last page fetched', function onceListener() {
        // Remove the listener from the element the first time the listener is run:
        document.removeEventListener('last page fetched', onceListener);
        paginationOff.value = true;
        loadingAllModal.value.close();
        selectAllItems();

      });
      
    }

    function selectAllItems(){
      if (selectedItems.value.length < store.state.data.searchResultItems.length){
        selectedItems.value = store.state.data.searchResultItems;
      }
      else{
        selectedItems.value = [];
      }
    }

    const handleAdd2Basket = async () =>{
      await store.dispatch('add2Cart',{items:selectedItems.value});
      
      selectedItems.value = [];
      selectModeOn.value = false;
    }

    function toggleItemSelect(item) {
      //console.log("item selected or unselected")
      
      let index=selectedItems.value.findIndex((i) => i?.id===item?.id)
      if(index===-1) {
        selectedItems.value.push(item)
      }
      else {
        selectedItems.value.splice(index, 1)
      }
    }

    const tagNotInChosenFilters = (tag) => {
      return chosenFilters.value.findIndex((o)=>{return isEqual(o,tag)}) == -1;
    }

    const handleChosenFilterClick = (e,tag)=> {console.log("filter got clicked",e,tag)};

    const handleChosenFilterDeleteClick = (e,tag)=> {console.log("filter delete",e,tag); removeFilter(e,{tag})};

    const handleSearchButtonPressed = async()=>{
      loadMoreItemsButtonText.value = loadMoreItemsButtonDefaultValue;
      await store.dispatch("emptySearchResultItems")//clear searchResults
      let name = searchTermInput.value.searchText;
      let results = await findItemsByNameAndLocation({
        name,tags:chosenFilters.value,locations:chosenLocations.value, area:chosenArea.value, 
        availableFrom:availableFrom.value, availableTill:availableTill.value 
      });
      if (results && results > 0){
        searchNextPageIfPaginationOff()
      }
    }

    const findItemsByNameAndLocation = async ({name,tags,locations,area,availableFrom,availableTill})=>{
      let lastItemOnPage = store.state.data.searchResultItems[store.state.data.searchResultItems.length-1]?.id;
      // console.log(
      //   "next page search for",name,
      //   "lastItemOnPage:",lastItemOnPage
      // );
      if (!lastItemOnPage){
        resetScrollPosition(); // only if first page. 
      }
      scrollToLastPosition();
      // console.log("findItemsByNameAndLocation",name,tags,locations,area,lastItemOnPage);
      if (name || availableFrom || availableTill || tags.length > 0 || locations.length > 0 || area 
          || atLeastOneNumericalFilterActive()){
        return await store.dispatch("searchItems",{text:name,tags:tags.map((t)=>t.tag),
          availableFrom, availableTill,
          minWeight:minWeight.value,maxWeight:maxWeight.value,
          minLength:minLength.value,maxLength:maxLength.value,
          minWidth:minWidth.value,maxWidth:maxWidth.value,
          minDepth:minDepth.value,maxDepth:maxDepth.value,
          containers:locations.map((l)=>l.id), area,fillStoreWithResults:true,
          lastItemOnPage: lastItemOnPage,
          },);
      }
      if (!lastItemOnPage) await store.dispatch("triggerToast",{message:"NO searchparameters defined. Will fetch only user's items"})
      //clearNumericalFilters();
      return await store.dispatch("searchAllMyItems");
    }


    const searchResultsBox = ref(null);
    let lastScrollPos = 0;

    function resetScrollPosition(){
      lastScrollPos = 0;
    }

    function scrollToLastPosition(){
      searchResultsBox.value.scrollTo({
        top: lastScrollPos,
        behavior: 'instant'
      })
    }

    const trackscroll = function(e){
      lastScrollPos = e.target.scrollTop;
    }

    function searchNextPageIfPaginationOff(){ // CONTINUE HERE: FIXEME: this should probably happen on action level not here
      if (paginationOff.value == false){
        searchNextPage()
      }
    }

    const searchNextPage = async () =>{
      console.log("next page, please...");
      let name = searchTermInput.value.searchText;
      let moreItemsLoadable = await findItemsByNameAndLocation({
        name,tags:chosenFilters.value,locations:chosenLocations.value, area:chosenArea.value,
        availableFrom:availableFrom.value, availableTill:availableTill.value 
      })
      if (!moreItemsLoadable) {
        console.log("NO MORE ITEMS");
        loadMoreItemsButtonText.value = "no more items to load";
        return
      }
      searchNextPageIfPaginationOff()
    }

    const toogleChosenFilterClick = async (e,tag)=>{
      console.log("toggle",tag);
      if (tagNotInChosenFilters(tag)){
        console.log("toogle should addFilter",tag);
        addFilter(e,tag)
      }
      else{
        console.log("toogle should removeFilter",tag);
        removeFilter(e,tag)
      }
    }

    const addFilter = async (e,tag)=> {
      console.log("filters active at the moment",chosenFilters.value);
      if(chosenFilters.value.some((v)=>v.tag== tag.tag))return
      store.dispatch("addSearchFilter",{filter:tag})
      setUrlParamsFromStoreFilters()
    }

    const removeFilter = async (e,tag)=>{
      await store.dispatch("removeSearchFilter",{filter:tag})
      await setUrlParamsFromStoreFilters()
    }

    onActivated( async ()=>{
      console.log("SearchItems got activated");
      scrollToLastPosition();
      setTimeout(initSearchItems,0); // this sucks. also working: a high number of await nextTick()
      //initSearchItems();
    })

    onMounted(async ()=>{
      //initSearchItems()
      setTimeout(()=>{

        let paginationNextButtonInViewObserver = new IntersectionObserver(handleNextButtonIntersection, {
          root: searchResultsBox.value,
          rootMargin: "0px",
          threshold:1.0,
        });
        paginationNextButtonInViewObserver.observe(loadMoreItemsButton.value);

        function handleNextButtonIntersection(intersections){
          console.log("intersection",intersections);
          if (intersections[0].isIntersecting){
            setTimeout(async()=>{
             let inview = await isElementInScrollView(intersections[0].target)
            if (inview){
              searchNextPage();
            }
          },1000)
          }
        }

      },500)
      

    })

    async function isElementInScrollView(element) {
      return new Promise((resolve) => {
          const observer = new IntersectionObserver(
              (entries, observerItself) => {
                  observerItself.disconnect();
                  resolve(entries[0].intersectionRatio === 1);
              }
          );
          observer.observe(element);
      });
    }

    const setUrlParamsFromStoreFilters = async ()=>{
      let filterNames = chosenFilters.value.map((e)=>e.tag);
      const searchFilterURLString = filterNames +"";
      if (searchFilterURLString){
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set("tags",searchFilterURLString);
        window.history.replaceState('','',newUrl.href);
      }
      else{
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("tags");
        window.history.replaceState('','',newUrl.href);
      }
      
    }

    const initSearchItems = async ()=>{
      
      // because this is loaded as dynamic component route.query does not work
      const params = new URLSearchParams(window.location.search);
      const tags = params.get("tags")?.split(",");
      if (tags) {
        tags.forEach((tag)=>addFilter(null,{tag}));
      }
      const searchText = params.get('search');
      if (searchText){
        searchTermInput.value.setText(searchText);
      }
      console.log("tags from url",tags,chosenFilters.value);

      await setUrlParamsFromStoreFilters();

      const rootContainer = params.get("rootContainer");
      //window.history.replaceState('','',window.location.pathname) // remove all queries
      if (store.state.data.searchResultItems.length > 0 && !rootContainer && !tags) {
        console.log("no search necessary. old search results and filters still in place.");
        return
      }


      if (tags && !rootContainer){
          handleSearchButtonPressed()
          return
      }

      if (rootContainer){
        let externalRootContainer = await store.dispatch('getItem',rootContainer)
        //console.log("externalRootContainer fetched",externalRootContainer);
        if (externalRootContainer){
          possibleRootContainers.value = [];
          possibleRootContainers.value.push(externalRootContainer);
          //console.log(possibleRootContainers.value);
          if (possibleRootContainers.value.length > 0){
            store.dispatch("addSearchFilterStorage",{filter:externalRootContainer});
            //window.location.href = removeParameterFromURL('rootContainer',window.location.href);
            handleSearchButtonPressed()
          }
          else{
            store.dispatch('triggerToast',{message:"item is empty"})
          }
          return
        }
      }

      possibleRootContainers.value = await store.dispatch('getAllRootContainers');
      resetScrollPosition();
      scrollToLastPosition();
      handleSearchButtonPressed();
      //store.dispatch("searchAllMyItems");
    }

    const availabilityAsString = ()=>{
      let fromString = availableFrom.value ? availableFrom.value.split('T')[0] : '1970-01-01'
      let tillString = availableTill.value ? availableTill.value.split('T')[0] : '2999-12-30'
      return `available: ${fromString} - ${tillString}`
    }

    return {
      tagNotInChosenFilters,
      searchResultsCollapsed,
      handleChosenFilterDeleteClick,
      removeFilter,
      chosenFilters,
      possibleRootContainers,
      handleChosenFilterClick,
      searchResults,
      searchResultTags,
      handleSearchButtonPressed,
      searchTermInput,
      searchTermResults,
      addFilter,
      chosenLocations,
      handleItemClicked,
      selectModeOn,
      selectedItems,
      handleSelectAllClicked,
      handleAdd2Basket,
      handleSelectClicked,
      locationFilterVisible,
      toogleChosenFilterClick,
      searchFilterVisible,
      minWeight,maxWeight,
      minLength,maxLength,
      minWidth,maxWidth,
      minDepth,maxDepth,
      chosenArea,
      trackscroll,
      searchResultsBox,
      searchNextPage,
      loadMoreItemsButton,
      loadMoreItemsButtonText: loadMoreItemsButtonText,
      onSearchLocationFilterClose,
      onSearchFilterClose,
      atLeastOneNumericalFilterActive,
      numericalFilters,
      clearNumericalFilters,
      availableFrom,
      availableTill,
      handleFromTimeChanged,
      handleTillTimeChanged,
      clearAvailabilityFilters,
      availabilityAsString,
      toggleSortItems,
      sortingAlgorithmChosen,
      handleNewSearchInput,
      handleTagAdded,
      handleSearchInputBlur,
      togglePagination,
      paginationOff,
      loadingAllModal
    }
  }
}
</script>