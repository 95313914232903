<template>
  <div class="is-flex is-flex-direction-column is-align-content-center is-align-items-center is-full-width">
    <div class="m-2 is-flex is-align-items-center">
     <canvas ref="CaptchaCanvas"/>
      <button class="button" @click="resetCaptcha">refresh</button>
    </div>
    <input ref="captchaInput" type="text" class="input" name="captcha" placeholder="type letters above" @input="checkCaptcha">
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'  

export default {
  name: 'Captcha',
  emits:["changed"],
  setup(props,{emit}){
      
      const captchaInput = ref(null);
      const captchaOutput = ref(null);
      const captchaImage = ref(null);
      
      onMounted(()=>{
        resetCaptcha();
      })

      const alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const CaptchaCanvas = ref(null);
      let signupCaptcha;

      async function resetCaptcha(){
          captchaInput.value.value = "";
          let refreshArr = [];
          for (let j = 1; j <= 7; j++) {
              refreshArr.push(alphaNums[Math.floor(Math.random() * alphaNums.length)]);
          }
          signupCaptcha = refreshArr.join('');
          drawText(signupCaptcha,CaptchaCanvas.value);
      }

      function drawText(c,canvasElement){
          let ctx = canvasElement.getContext('2d');
          ctx.font = "70px monospace";
          ctx.fillStyle = "darkgreen";
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          ctx.fillText(c, 0, 90);
      }


      function checkCaptcha(){
        if (captchaInput.value.value == signupCaptcha){
          return emit('changed',captchaInput.value.value)
        }
        return emit('changed','')
      }

      return {
        captchaImage,
        captchaOutput,
        captchaInput,
        resetCaptcha,
        checkCaptcha,
        CaptchaCanvas
     }
  }
}
</script>
