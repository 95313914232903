<template>
  <div class="tab-bar ignored-when-printed" v-if="tabbarVisible">
    <router-link to="/" >
      <Icon
          class="is-size-5 has-text-dark m-4"
         
          :icon="['fa', 'user']"
      />

    </router-link>
    <router-link to="/Scan">
      <Icon
          class="is-size-5 has-text-dark m-4"
          :icon="['fa', 'expand']"
      />
    </router-link>
    <router-link to="/SearchItems">
        <Icon
          class="is-size-5 has-text-dark m-4"
          :icon="['fa', 'search']"
        />
    </router-link>
    <router-link to="/AddDing">
      <Icon
          class="is-size-5 has-text-dark m-4"
          :icon="['fa', 'camera']"
      />
    </router-link>
    <router-link to="/Reservations">
      <Icon
          class="is-size-5 has-text-dark m-4"
          :icon="['far', 'calendar']"
      />
    </router-link>
    <router-link to="/Basket">
      <span class="is-number-tag has-background-pink-4 tag is-rounded has-text-dark">{{$store.state["data"]["basket"].length}}</span>
      <Icon
          class="is-size-4 has-text-dark m-5"
          :icon="['fa', 'inbox']"
      />
    </router-link>
  </div>
</template>

<script>
import Icon from '@/components/Template/Icon.vue';
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  name: 'TabBar',
  components:{
    Icon
  },
  setup(){
    const store = useStore();
    const tabbarVisible = computed(()=>store.state.selected.tabbarVisible);
    return{tabbarVisible}
  }
}
</script>

<style>

#nav {
 font-size: 0.5em;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

